@tailwind base;
@tailwind components;
@tailwind utilities;

.grayscale {
  filter: grayscale(1);
}

html, body {
  font-family: 'Open Sans', sans-serif;
}
